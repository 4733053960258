import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {RawComponent} from "./raw/raw.component";
import {WelcomeComponent} from "./welcome/welcome.component";
import {ChatComponent} from "./chat/chat.component";


const routes: Routes = [
  { path: 'chat', component: ChatComponent },
  { path: 'raw', component: RawComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
