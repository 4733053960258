import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as faker from 'faker';
import {Connection, WebsocketService} from "../websocket.service";

@Component({
    selector: 'app-chat',
    template: `
        <div class="chat">
            <div class="chat-title">
                <h1>{{ identity.firstName }} {{ identity.lastName }}</h1>
                <h2>{{identity.jobTitle}}</h2>
                <figure class="avatar">
                    <img src="{{identity.avatar}}"/></figure>
                
                <div class="status">
                    <div *ngIf="connectionOpen" class="green led" over></div>
                    <div *ngIf="!connectionOpen" class="red led"></div>
                </div>
            </div>
            <div class="messages">
                <div class="messages-content">
                    <div class="message new" *ngFor="let message of messages">
                        <figure class="avatar"><img
                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/156381/profile/profile-80.jpg"/>
                        </figure>
                        {{message}}</div>
                </div>
            </div>
            <div class="message-box">
                <textarea #messageInput type="text" class="message-input" placeholder="Type message..." autofocus (keydown.enter)="sendMessage(); $event.preventDefault()"
                          [(ngModel)]="message"></textarea>
                <button type="submit" class="message-submit" (click)="sendMessage()">Send</button>
            </div>

        </div>
        <div class="bg"></div>
    `,
    styleUrls: [
        "./chat.scss"
    ]
})
export class ChatComponent implements OnInit, OnDestroy {
    @ViewChild('messageInput') messageInput: ElementRef;
    connection: Connection;
    url = 'wss://stingray-api-sydney.superservice.com/websockets-sydney';

    messages: string[] = [];
    message: string;
    identity = {
        firstName: faker.name.firstName(),
        lastName: faker.name.lastName(),
        jobTitle: faker.name.jobTitle(),
        avatar: faker.image.avatar(),
    };
    connectionOpen: boolean = false;

    constructor(private websocketService: WebsocketService) {
    }

    async ngOnInit(): Promise<void> {
        this.connection = await this.websocketService.connect(this.url);
        this.connectionOpen = true;
        this.connection.messages.subscribe(messageJson => {
            let messageData = JSON.parse(messageJson);
            switch (messageData.message) {
                case "text": {
                    this.messages.push(messageData.text);
                    break;
                }
            }
        });

        this.connection.websocket.addEventListener('close', evt => {
            this.connectionOpen = false;
        });

        this.connection.sendMessage(JSON.stringify({
            message: "identify",
            username: `${this.identity.firstName} ${this.identity.lastName}`
        }))
    }

    ngOnDestroy(): void {
        this.connection.disconnect();
    }

    sendMessage() {
        this.connection.sendMessage(JSON.stringify({
                message: "broadcast",
                text: this.message
            })
        );
        this.message = "";
        this.messageInput.nativeElement.focus();
    }
}
