import {Component, OnInit} from '@angular/core';
import {Connection, WebsocketService} from "../websocket.service";
import {Subject} from "rxjs";

@Component({
    selector: 'app-raw',
    template: `
        <table>
            <tr>
                <td><label for="url">URL:</label></td>
                <td><input id="url" [(ngModel)]="url" style="width: 320px;"></td>
                <td>
                    <button (click)="connect();">{{connectLabel}}</button>
                </td>
            </tr>
            <tr>
                <td><label for="message">Message:</label></td>
                <td><input id="message" [(ngModel)]="message" style="width: 320px;"></td>
                <td>
                    <button (click)="sendMsg();">Send Message</button>
                </td>
            </tr>
            <tr>
                <td><label for="output">Output:</label></td>
                <td colspan="2"><textarea rows="20" style="width:100%" id="output" [(ngModel)]="output"
                ></textarea></td>
            </tr>
            <tr>
                <td><label>Examples:</label></td>
                <td colspan="2" style="text-align: left">
                    <code *ngFor="let example of examples" (click)="this.message = example">
                        {{example}}<br/>
                    </code>
                </td>
            </tr>
            <tr>
                <td><label>URLs:</label></td>
                <td colspan="2" style="text-align: left">
                    <code *ngFor="let url_candidate of urls" (click)="this.url = url_candidate">
                        {{url_candidate}}<br/>
                    </code>
                </td>
            </tr>
        </table>
    `,
    styles: [`
        td {
            vertical-align: top;
            font-size: 16px;
        }
    `]
})
export class RawComponent {
    connection: Connection;
    urls = [
        'wss://stingray-api-sydney.superservice.com/websockets-sydney',
        'wss://stingray-api-oregon.superservice.com/websockets-oregon',
        'wss://stingray-api-ireland.superservice.com/websockets-ireland',
        'wss://websocket-api-sydney.stingray-destination.com/websockets-sydney',
        'wss://websocket-api-oregon.stingray-destination.com/websockets-oregon',
        'wss://websocket-api-ireland.stingray-destination.com/websockets-ireland',
        'wss://dmsi-apac.superservice.com/v1/messaging',
    ];
    url = this.urls[0];
    message = '{"message":"stats"}';
    connectLabel: string = "Connect";
    messages$: Subject<any>;
    output: string = "";
    examples: string[] = [
        JSON.stringify({message: "stats"}),
        JSON.stringify({message: "connections"}),
        JSON.stringify({message: "send", connectionId: "123", text: "hi there"}),
        JSON.stringify({message: "broadcast", text: "hello"}),
        JSON.stringify({message: "identify", username: "BillyBob"}),
    ];

    constructor(private websocketService: WebsocketService) {
    }

    sendMsg() {
        console.log("new message from client to websocket: ", this.message);
        this.connection.sendMessage(this.message);
    }

    async connect() {
        if (!this.connection) {
            this.connection = await this.websocketService.connect(this.url);
            this.connection.messages.subscribe({
                next: data => {
                    this.output = data + '\n' + this.output;
                },
                error: err => {
                    this.output = `---------------\n----Error:----\n${JSON.stringify(err)}\n---------------\n\n` + this.output;
                },
                complete: () => {
                    this.output = '---------------\n----Closed----\n---------------\n\n' + this.output;
                    this.connectLabel = "Connect";
                    this.connection = null;
                    this.messages$ = null;
                }
            });
            this.connectLabel = "Disconnect";
        } else {
            this.connection.disconnect();
            this.connectLabel = "Connect";
            this.connection = null;
            this.messages$ = null;
        }
    }
}
