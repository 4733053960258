import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome',
  template: `
    <h1>
      Welcome to {{title}}!
    </h1>
      <div>
        <a routerLink="/raw">Raw WebSocket Connector</a>
      </div>
      <div>
        <a routerLink="/chat">Chat</a>
      </div>
  `,
  styles: []
})
export class WelcomeComponent implements OnInit {
  title = 'Stingray Edge';

  constructor() { }

  ngOnInit(): void {
  }

}
