import {Injectable} from '@angular/core';
import {Observable, Observer, Subject} from 'rxjs';
import {map} from "rxjs/operators";

export interface Connection {
    websocket: WebSocket,
    rawMessages: Subject<MessageEvent>
    messages: Subject<string>
    sendMessage: (message) => void,
    disconnect: () => void
}

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {
    public async connect(url): Promise<Connection> {
        return new Promise((resolve, reject) => {
            try {
                let websocket = new WebSocket(url);
                websocket.onopen = function () {
                    let observable = Observable.create((obs: Observer<MessageEvent>) => {
                            websocket.onmessage = obs.next.bind(obs);
                            websocket.onerror = obs.error.bind(obs);
                            websocket.onclose = obs.complete.bind(obs);
                            return websocket.close.bind(websocket);
                        }
                    );
                    let observer = {
                        next: (data: string) => {
                            if (websocket.readyState === WebSocket.OPEN) {
                                websocket.send(data);
                            }
                        }
                    };

                    let subject = Subject.create(observer, observable);
                    let messages = <Subject<string>>subject.pipe(map((response: MessageEvent): string => {
                        return response.data;
                    }));
                    let connection: Connection = {
                        websocket: websocket,
                        rawMessages: subject,
                        messages: messages,
                        sendMessage: (message) => messages.next(message),
                        disconnect: () => websocket.close()
                    };
                    console.log(`Connected successfully to ${url}!`);

                    const pingInterval = setInterval(() => {
                        if (connection.websocket.readyState === WebSocket.OPEN) {
                            console.log('ping');
                            connection.messages.next(JSON.stringify({message: "ping"}));
                        } else {
                            clearInterval(pingInterval);
                        }
                    }, 1000 * 60 * 9); // every 9 minutes

                    resolve(connection);
                };
                websocket.onerror = function (err) {
                    console.log(`An error occurred attempting to connect to ${url}:\n${JSON.stringify(err)}`);
                    reject(err);
                };
                websocket.onclose = (ev: CloseEvent) => {
                    let reason = {
                        code: ev.code,
                        reason: ev.reason,
                        wasClean: ev.wasClean
                    };
                    console.log(`WebSocket closed:\n${JSON.stringify(reason)}`)
                }
            } catch (e) {
                console.log('here');
                reject(e);
            }
        });
    }
}
