import {Component} from '@angular/core';

@Component({
    selector: 'app-root',
    template: `
        <div class="content">
            <table style="width: 100%">
                <tr>
                    <td class="bannerContentOuterCell">
                        <div class="bannerContent">
                        <a routerLink="/welcome">
                            <img width="300" alt="Angular Logo" src="/assets/stingray-blue.png">
                        </a>
                        </div>
                    </td>
                    <td class="routerContentOuterCell">
                        <div class="routerContent">
                            <router-outlet></router-outlet>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    `,
    styles: [`
        .bannerContentOuterCell {
            vertical-align: top;
            width: 30px;
        }
        .routerContentOuterCell {
            vertical-align: top;
        }
        .bannerContent {
            padding-right: 50px;
        }
    `]
})
export class AppComponent {


}
